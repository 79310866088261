import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: L-Holds (Hanging & on parallettes) & Rope Climbs`}</p>
    <p>{`then,`}</p>
    <p>{`200M Run`}</p>
    <p>{`10-KBS’s (53/35)`}</p>
    <p>{`10-T2B`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-KBS’s`}</p>
    <p>{`15-T2B`}</p>
    <p>{`800M Run`}</p>
    <p>{`20-KBS’s`}</p>
    <p>{`20-T2B`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-KBS’s`}</p>
    <p>{`15-T2B`}</p>
    <p>{`200M Run`}</p>
    <p>{`10-KBS’s`}</p>
    <p>{`10-T2B`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be judging 17.5 from 10-12 today at The Ville.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids today.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Starting next month we will no longer have a 6:30pm class on
Tuesday & Thursday at East due to low attendance.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      